import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import React from 'react';
import TargetAudience from '../../components/alliances/target-audience';
import ContactForm from '../../components/contact/contact-form';
import SEO from '../../components/seo';
import ReactMarkdown from 'react-markdown';
import { wholeSchoolsData } from '../../data/whole-school-course';
import CourseCard from '../../components/whole-school-anxiety/CourseCard';
import CoursePreviewCard from '../../components/courses/course-preview-card';

function AlliancesPage({ data }) {
  const seoDescription =
    data.pageSeo.edges[0].node.description.internal.content;
  const image = getImage(data.header.nodes[0]?.headerImage?.gatsbyImage);
  const { heading } = data.header.nodes[0];

  const intro = data.header.nodes[0].introParagraph.internal.content;

  return (
    <>
      <SEO
        title="Whole-school approach to managing anxiety"
        description={seoDescription}
      />
      <Flex
        flexDir="column"
        justify="start"
        align="center"
        w="100%"
        mt={8}
        mb={16}
      >
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Flex
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            w={{ base: '100%', '2xl': '50%' }}
            mr={{ base: 0, md: 20 }}
            order={{ base: 2, md: 1 }}
          >
            <Heading as="h1" mt={10}>
              {heading}
            </Heading>
            <Text mt={3} color="gray.600">
              <ReactMarkdown>{intro}</ReactMarkdown>
            </Text>
          </Flex>
          <Box
            w={{ base: '100%', '2xl': '50%' }}
            overflow="hidden"
            boxShadow="lg"
            borderRadius="lg"
            mt={10}
            order={{ base: 1, md: 2 }}
          >
            <Img
              image={image}
              alt="Child psychologist and parenting expert Michael Hawton teaching."
            />
          </Box>
        </Flex>
        <Grid
          templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
          gap={5}
          my={{ base: 12, md: 32 }}
        >
          <CoursePreviewCard
            title={data.anxietyProjectCourse.edges[0].node.courseName}
            body={data.anxietyProjectCourse.edges[0].node.shortDescription}
            image={getImage(
              data.anxietyProjectCourse.edges[0].node.courseImage.gatsbyImage
            )}
            alt={data.anxietyProjectCourse.edges[0].node.courseImage.title}
            category={data.anxietyProjectCourse.edges[0].node.courseCategory}
            slug={data.anxietyProjectCourse.edges[0].node.slug}
          />
          <CoursePreviewCard
            title={data.riotCourse.edges[0].node.courseName}
            body={data.riotCourse.edges[0].node.shortDescription}
            image={getImage(
              data.riotCourse.edges[0].node.courseImage.gatsbyImage
            )}
            alt={data.riotCourse.edges[0].node.courseImage.title}
            category={data.riotCourse.edges[0].node.courseCategory}
            slug={data.riotCourse.edges[0].node.slug}
          />
        </Grid>
        <Flex justifyContent="space-between" alignItems="flex-start" w="100%">
          <Flex
            flexDir="column"
            justify="start"
            align="start"
            w={{ base: '100%', lg: '50%' }}
            mr={{ base: 0, lg: 20 }}
          >
            <Heading as="h2" fontWeight="semibold">
              Want to know more? Contact us
            </Heading>
            <ContactForm formName="alliances" />
          </Flex>
          <Box
            w="50%"
            display={{ base: 'none', lg: 'block' }}
            borderRadius="md"
            overflow="hidden"
            boxShadow="lg"
          >
            <Img
              image={getImage(
                data.contact.nodes[0].childImageSharp.gatsbyImageData
              )}
              alt="Contact image"
            />
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

export default AlliancesPage;

export const query = graphql`
  query {
    pageSeo: allContentfulPageSeo(
      filter: { pageName: { eq: "in-house-training" } }
    ) {
      edges {
        node {
          pageName
          description {
            internal {
              content
            }
          }
        }
      }
    }
    header: allContentfulPageHeader(
      filter: { heading: { eq: "Whole-School Approach to Managing Anxiety" } }
    ) {
      nodes {
        headerImage {
          gatsbyImage(width: 1920)
        }
        heading
        introParagraph {
          internal {
            content
          }
        }
      }
    }
    contact: allFile(
      filter: { relativePath: { eq: "contact/contact-books.jpg" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
    }
    resilience: allFile(
      filter: { relativePath: { eq: "alliances/education.png" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
    }
    anxietyCoach: allFile(
      filter: { relativePath: { eq: "alliances/school.png" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
    }
    anxietyProject: allFile(
      filter: { relativePath: { eq: "alliances/parents.png" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
    }
    anxietyProjectCourse: allContentfulCourse(
      filter: { slug: { eq: "the-anxiety-project" } }
    ) {
      edges {
        node {
          forParents
          courseCategory
          courseImage {
            title
            gatsbyImage(width: 1920)
          }
          courseName
          shortDescription {
            raw
          }
          slug
        }
      }
    }
    riotCourse: allContentfulCourse(
      filter: { slug: { eq: "resilience-in-our-teens" } }
    ) {
      edges {
        node {
          forParents
          courseCategory
          courseImage {
            title
            gatsbyImage(width: 1920)
          }
          courseName
          shortDescription {
            raw
          }
          slug
        }
      }
    }
  }
`;
